<template>
  <div :class="$style.HeaderUser">
    <UiVButton v-if="!isLogged" size="small" icon @click="openModal">
      <template #icon>
        <IconsSign :class="$style.icon" />
      </template>
      {{ t('Войти') }}
    </UiVButton>
    <div v-else :class="$style.user">
      <span>{{ t('Здравствуйте') }}, {{ email }} </span>
      <IconsUser :class="$style.iconUser" />

      <div :class="$style.menu">
        <div :class="$style.menuItem">
          <NuxtLink :to="localePath('/account')" :class="$style.menuLink">
            {{ t('Личный кабинет') }}
          </NuxtLink>
        </div>
        <div :class="$style.menuItem">
          <NuxtLink :class="$style.menuLink" @click="onClickLogout">
            {{ t('Выход') }}
          </NuxtLink>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia';
import LoginModal from '~/components/common/LoginModal.vue';
import { useUserStore } from '~/stores/useUserStore';

const { user, isLogged } = storeToRefs(useUserStore());

const email = computed(() => user.value?.email);
const openModal = () => {
  const { $modal } = useNuxtApp();
  $modal.open(markRaw(LoginModal));
};

const { t } = useI18n({
  useScope: 'local',
});

const localePath = useLocalePath();

function onClickLogout() {
  useUserStore().logout();
}
</script>

<i18n lang="json">
{
  "ru": {
    "Войти": "Войти",
    "Здравствуйте": "Здравствуйте",
    "Личный кабинет": "Личный кабинет",
    "Выход": "Выход"
  },
  "en": {
    "Войти": "Login",
    "Здравствуйте": "Hello",
    "Личный кабинет": "Personal account",
    "Выход": "Logout"
  }
}
</i18n>

<style lang="scss" module>
.HeaderUser {
  margin-left: 1rem;
}

.icon {
  width: 1.2rem;
  height: 1.5rem;
  color: $base-900;
}

.user {
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;

  span {
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 190px;
    overflow: hidden;
  }

  &:hover {
    span {
      color: $brand-green;
    }

    .menu {
      opacity: 1;
      visibility: visible;
    }
  }
}

.iconUser {
  width: 1.8rem;
  height: 1.8rem;
}

.menu {
  top: 32px;
  right: 0;

  position: absolute;
  width: 230px;
  padding: 1.6rem 2.2rem;
  background: linear-gradient(90deg, #223540 0%, #1d2d36 100%);
  backdrop-filter: blur(4px);
  border-radius: 15px;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease-in-out;

  &:before {
    content: '';
    position: absolute;
    height: 32px;
    top: -32px;
    width: 100%;
  }
}

.menuLink {
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;

  &:hover {
    color: $brand-green;
  }
}
</style>
