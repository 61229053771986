import { defineStore } from 'pinia';
import { calculatorApi } from '~/utils/api/calculator';
import type { PairData } from '~/types/calculator';

const namespace = 'calculator';
export const useCalculatorStore = defineStore(namespace, () => {
  const fromCurrencyId = ref(0);

  const setFromCurrency = (value: number) => {
    fromCurrencyId.value = value;
  };

  const toCurrencyId = ref(0);

  const setToCurrency = (value: number) => {
    toCurrencyId.value = value;
  };

  const fromCurrencies = ref<number[] | null>(null);

  const setFromCurrencies = (value: number[]) => {
    fromCurrencies.value = value;
  };

  const toCurrencies = ref<number[] | null>(null);

  const setToCurrencies = (value: number[]) => {
    toCurrencies.value = value;
  };

  const refferal = ref<string | null>(null);

  const setRefferal = (value: string) => {
    refferal.value = value;
  };

  const pairData = ref<PairData | null>(null);

  const setPairData = (value: PairData) => {
    pairData.value = value;
  };

  const course = ref<number | null>(null);

  const setCourse = (value: number) => {
    course.value = value;
  };

  const congestion = ref<number | null>(null);

  const setCongestion = (value: number) => {
    congestion.value = value;
  };

  const fromValue = ref<number | null>(null);

  const setFromValue = (value: number) => {
    fromValue.value = value;
  };

  const initCalculator = async () => {
    const { query } = useRoute();
    const { $i18n } = useNuxtApp();
    const { data } = await calculatorApi.initCalculator({
      cur_from: query.cur_from,
      cur_to: query.cur_to,
      locale: $i18n.locale.value,
    });
    if (data.value) {
      setFromCurrencies(data.value.fromIds);
      setToCurrencies(data.value.toIds);
      if (!fromCurrencyId.value) {
        setFromCurrency(data.value.fromCurrencyId);
      }
      if (!toCurrencyId.value) {
        setToCurrency(data.value.toCurrencyId);
      }
      setPairData(data.value.pairData);
      updateData();
    }
  };

  const updateData = () => {
    setCourse(pairData.value.course);
    setCongestion(pairData.value.structure.congestion);
    const minValue = pairData.value.from.min
      ? pairData.value.from.min
      : pairData.value.to.min / course.value;
    setFromValue(minValue);
  };

  return {
    fromCurrencyId,
    setFromCurrency,
    toCurrencyId,
    setToCurrency,
    fromCurrencies,
    setFromCurrencies,
    toCurrencies,
    setToCurrencies,
    pairData,
    setPairData,
    initCalculator,
    course,
    setCourse,
    fromValue,
    setFromValue,
    updateData,
    setRefferal,
    refferal,
    congestion,
  };
});
