import { defineNuxtPlugin } from '#app';

export default defineNuxtPlugin(nuxtApp => {
    const modal = {
        open(component, data) {
            nuxtApp.$bus.$emit('modal:open', { component, data });
        },
        close: () => nuxtApp.$bus.$emit('modal:close'),

    };
    nuxtApp.provide('modal', modal);
});
