<template>
  <header :class="[$style.TheHeader, classes]" class="container_max">
    <div :class="$style.wrapper">
      <NuxtLink :to="localePath('/')" :class="$style.logo">
        <CommonTheLogo />
      </NuxtLink>
      <nav :class="$style.nav">
        <ul :class="$style.navList">
          <li
            :class="[
              $style.navItem,
              { [$style._active]: route.path === localePath('/rules') },
            ]"
          >
            <NuxtLink :to="localePath('/rules')" :class="$style.navLink">
              {{ $t('rules') }}
            </NuxtLink>
          </li>
          <li
            :class="[
              $style.navItem,
              { [$style._active]: route.path === localePath('/partners') },
            ]"
          >
            <NuxtLink :to="localePath('/partners')" :class="$style.navLink">
              {{ $t('partners') }}
            </NuxtLink>
          </li>
          <li
            :class="[
              $style.navItem,
              { [$style._active]: route.path === localePath('/aml') },
            ]"
          >
            <NuxtLink :to="localePath('/aml')" :class="$style.navLink"
              >AML/KYC</NuxtLink
            >
          </li>
          <li
            :class="[
              $style.navItem,
              { [$style._active]: route.path === localePath('/faq') },
            ]"
          >
            <NuxtLink :to="localePath('/faq')" :class="$style.navLink"
              >FAQ</NuxtLink
            >
          </li>
          <li
            :class="[
              $style.navItem,
              { [$style._active]: route.path === localePath('/blog') },
            ]"
          >
            <NuxtLink :to="localePath('/blog')" :class="$style.navLink">
              {{ $t('blog') }}
            </NuxtLink>
          </li>
          <li
            :class="[
              $style.navItem,
              { [$style._active]: route.path === localePath('/contacts') },
            ]"
          >
            <NuxtLink :to="localePath('/contacts')" :class="$style.navLink">
              {{ $t('contacts') }}
            </NuxtLink>
          </li>
        </ul>
      </nav>
      <div :class="$style.right">
        <LayoutHeaderLanguagePicker />
        <!--        <client-only>-->
        <!--          <UiVButton-->
        <!--            v-if="!loggedIn"-->
        <!--            size="small"-->
        <!--            icon-->
        <!--            :class="$style.btn"-->
        <!--            @click="openModal"-->
        <!--          >-->
        <!--            <template #icon>-->
        <!--              <IconsSign :class="$style.icon" />-->
        <!--            </template>-->
        <!--            {{ $t('login') }}-->
        <!--          </UiVButton>-->
        <!--          <div v-else :class="$style.user">-->
        <!--            <span>{{ $t('hello') }}, {{ email }}!</span>-->
        <!--            <IconsUser :class="$style.iconUser" />-->
        <!--            <div :class="$style.userMenu">-->
        <!--              <ul :class="$style.userMenuList">-->
        <!--                <li :class="$style.userMenuItem">-->
        <!--                  <NuxtLink to="/account" :class="$style.userMenuLink">-->
        <!--                    {{ $t('personal account') }}-->
        <!--                  </NuxtLink>-->
        <!--                </li>-->
        <!--                <li :class="$style.userMenuItem" @click="onClickLogout">-->
        <!--                  <NuxtLink :class="$style.userMenuLink">-->
        <!--                    {{ $t('exit') }}-->
        <!--                  </NuxtLink>-->
        <!--                </li>-->
        <!--              </ul>-->
        <!--            </div>-->
        <!--          </div>-->
        <!--        </client-only>-->
        <HeaderUser :class="$style.user" />
      </div>
      <div :class="$style.mobileMenu" @click="onMobileMenuClick">
        <IconsBurger :class="$style.mobileMenuIcon" />
      </div>
    </div>
  </header>
</template>

<script setup>
import MainMenuModal from '~/components/common/MainMenuModal.vue';
import HeaderUser from '~/components/layout/header/HeaderUser.vue';

const { $modal } = useNuxtApp();
const isHidden = ref(false);
const start = ref(null);
const end = ref(null);
const isScrolling = ref(null);
const distance = ref(null);
const scrollPrev = ref(0);
const style = useCssModule();
const { $jwtAuth } = useNuxtApp();

const route = useRoute();
const router = useRouter();

const localePath = useLocalePath();

function onClickLogout() {
  $jwtAuth.logout();
  router.push('/');
}

const classes = computed(() => ({
  [style._hidden]: isHidden.value,
}));

function handleScrollDown() {
  isHidden.value = true;
}

function handleScrollUp() {
  isHidden.value = false;
}

function checkVisibility() {
  if (!start.value) {
    start.value = window.pageYOffset;
  }

  window.clearTimeout(isScrolling.value);

  isScrolling.value = setTimeout(() => {
    end.value = window.pageYOffset;
    distance.value = end.value - start.value;

    if (end.value > 70 && end.value > scrollPrev.value) {
      handleScrollDown();
    } else {
      handleScrollUp();
    }

    scrollPrev.value = end.value;
  }, 20);
}

onMounted(() => {
  window.addEventListener('scroll', checkVisibility);
});

onBeforeUnmount(() => {
  window.removeEventListener('scroll', checkVisibility);
});

const onMobileMenuClick = () => {
  lockBody();
  // open();
  $modal.open(markRaw(MainMenuModal));
};
</script>

<style lang="scss" module>
.TheHeader {
  position: fixed;
  top: 0;
  left: 50%;
  z-index: 16;
  width: calc(100% - 3.2rem);
  height: $header-h;
  transform: translate(-50%, 0);
  transition: transform 0.5s ease-in-out;

  &._hidden {
    transform: translate(-50%, -180%);
  }

  @include respond-to(sm) {
    width: 100%;
    background-color: rgba($body-bg, 0.9);
    $box-shadow: 0 0 1.6rem rgba($base-1000, 0.2);
  }

  @include respond-to(xs) {
    position: relative;
    left: 0;
    width: 100%;
    height: 60px;
    padding-left: 16px;
    padding-right: 16px;
    transform: translate(0, 0);

    &._hidden {
      transform: translate(0, 0);
    }
  }
}

.wrapper {
  display: flex;
  align-items: center;
  padding: 0 3.6rem;
  height: 100%;
  width: 100%;
  background: #1d2532;
  border-radius: 0 0 30px 30px;

  @include respond-to(sm) {
    padding: 0;
    background: transparent;
  }
}

.logo {
  display: inline-flex;
  flex-shrink: 0;
  max-width: 100%;

  svg {
    width: 175px;
    max-width: 100%;
    height: 39px;
  }

  @include respond-to(md) {
    svg {
      width: 135px;
    }
  }

  @include respond-to(sm) {
    svg {
      width: 135px;
      height: 30px;
    }
  }
}

.nav {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);

  @include respond-to(sm) {
    display: none;
  }
}

.navList {
  display: flex;
  align-items: center;
}

.navItem {
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 1.8rem;

  &:hover {
    .navLink {
      color: $brand-green;
    }
  }

  &._active {
    .navLink {
      color: $brand-green;
    }
  }

  &:not(:last-child) {
    margin-right: 4.15rem;

    @include respond-to(md) {
      margin-right: 2rem;
    }
  }
}

.navLink {
  transition: all $base-transition;
}

.right {
  display: flex;
  align-items: center;
  margin-left: auto;

  @include respond-to(sm) {
    display: none;
  }
}

.mobileMenu {
  display: none;
  margin-left: auto;

  @include respond-to(sm) {
    display: block;
  }
}

.mobileMenuIcon {
  width: 4rem;
  height: 1rem;
  color: $brand-green;
}

.icon {
  width: 1.2rem;
  height: 1.5rem;
  color: $base-900;
}
</style>
