import { apiFetch, useApiFetch } from '~/utils/fetch/fetchApi';
import type { Course, Direction, Reserve } from '~/types/calculator';

export const calculatorApi = {
  getDirections,
  getReserves,
  initCalculator,
  getCourse,
  pair,
  fetchTech,
};

function getDirections() {
  return useApiFetch<Direction[]>('/api/v1/calculator/');
}

function getReserves() {
  return useApiFetch<Reserve[]>('/api/v1/calculator/reserves/', {
    server: false,
  });
}

function getCourse(fromDirections: number, toDirection: number) {
  return apiFetch<Course>(
    `/api/v1/calculator/course/${fromDirections}/${toDirection}/`
  );
}

function pair(from: number, to: number) {
  return apiFetch(`/api/v1/calculator/pair/${from}/${to}/`);
}

function initCalculator({
  cur_from,
  cur_to,
  locale,
}: {
  cur_from: string;
  cur_to: string;
  locale: string;
}) {
  return useFetch('/front-api/calculator/currencies', {
    query: {
      cur_from,
      cur_to,
      locale,
    },
  });
}
interface fetchTechResponse {
  technicalMode: {
    time: number;
    on: boolean;
  };
}
function fetchTech() {
  return useApiFetch<fetchTechResponse>('/api/v1/calculator/tech/', {
    lazy: true,
  });
}
