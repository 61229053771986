<template>
    <transition
        name="overlay-appear"
        @enter="isContentVisible = true"
        @after-leave="afterOverlayClose"
    >
        <div
            v-if="isOverlayVisible"
            ref="overlay"
            :class="[$style.overlay, classes]"
            @click.self="onClose"
        >
            <component
                :is="component"
                :data="options"
                :visible="isContentVisible"
                @close="onClose"
                @after-enter="isOverflowing = true"
                @before-leave="isOverflowing = false"
                @after-leave="afterContentClose"
            />
        </div>
    </transition>
</template>

<script>

export default {
    data() {
        return {
            component: null,
            options: null,
            newComponent: null,
            newOptions: null,
            isOverlayVisible: false,
            isContentVisible: false,
            isOverflowing: false,
        };
    },

    computed: {
        classes() {
            return [
                this.options && this.options.className ? this.options.className : '',
                {
                    [this.$style._overflow]: this.isOverflowing,
                },
            ];
        },
    },

    watch: {
        $route() {
            this.onClose();
        },
    },

    beforeMount() {
        this.$bus.$on('modal:open', this.onOpen);
        this.$bus.$on('modal:close', this.onClose);
        document.addEventListener('keydown', this.handleKeydown);
    },

    beforeUnmount() {
        this.$bus.$off('modal:open', this.onOpen);
        this.$bus.$off('modal:close', this.onClose);
        document.removeEventListener('keydown', this.handleKeydown);
    },

    methods: {
        onOpen({ component, data }) {
            if (this.isOverlayVisible) {
                this.newComponent = component;
                this.newOptions = data || null;
                this.isContentVisible = true;
                // console.warn('[TheModal] Модальное окно уже открыто');
            } else {
                lockBody();
                this.isOverlayVisible = true;
                this.component = component;
                if (data) {
                    this.options = data;
                }
            }
        },

        onClose() {
            this.isContentVisible = false;
        },

        handleKeydown(e) {
            if (this.isOverlayVisible && e.key === 'Escape') {
                this.onClose();
            }
        },

        afterContentClose() {
            this.isOverflowing = false;

            if (this.newComponent) {
                this.component = this.newComponent;
                this.options = this.newOptions;
                this.newComponent = null;
                this.newOptions = null;

                this.$nextTick(() => {
                    this.isContentVisible = true;
                });
            } else {
                this.component = null;
                this.options = null;
                this.isOverlayVisible = false;
            }
        },

        afterOverlayClose() {
            unlockBody();
        },
    },
};
</script>

<style lang="scss" module>
.overlay {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 98;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-color: rgba($base-1000, .34);
    -webkit-overflow-scrolling: touch;

    &._overflow {
        overflow-y: auto;
    }

    &:global(.overlay-appear-enter-active) {
        transition: all 0.4s;
    }

    &:global(.overlay-appear-leave-active) {
        transition: all 0.2s;
        opacity: 0;
    }

    &:global(.overlay-appear-enter-from) {
        opacity: 0;
    }
}
</style>
