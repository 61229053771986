<template>
  <div ref="picker" :class="$style.LanguagePicker">
    <div :class="$style.title" @click="onPickerClick">
      {{ currentLocale }}
      <IconsFilledArrow
        name="arrow-down"
        :class="[$style.icon, { [$style._active]: active }]"
      />
    </div>
    <div :class="[$style.picker, { [$style._visible]: active }]">
      <div :class="$style.caption">{{ t('Выберите язык') }}</div>
      <div :class="$style.items">
        <NuxtLink
          v-for="loc in locales"
          :key="loc.code"
          :to="switchLocalePath(loc.code)"
          :class="[$style.item, { [$style._active]: locale === loc.code }]"
          @click.prevent.stop="handleChangeLanguage"
        >
          {{ loc.name }}
        </NuxtLink>
      </div>
      <IconsClose :class="$style.close" @click="onPickerClick" />
    </div>
  </div>
</template>

<script setup>
const active = ref(false);
const picker = ref(null);

onClickOutside(picker, () => {
  active.value = false;
});

const onPickerClick = () => {
  active.value = !active.value;
};

const { locale, locales } = useI18n();
const switchLocalePath = useSwitchLocalePath();
// const switchLocalePath = useSwitchLocalePath();
const currentLocale = computed(() => {
  const currentLocale = locales.value.find((i) => i.code === locale.value);
  return currentLocale?.code || '';
});

// const availableLocales = computed(() => (locales.value).filter(i => i.code !== locale.value));

function handleChangeLanguage() {
  // if (locale.value === val) {
  //     return;
  // }
  // // setLocale(val).then(() => {
  // //     location.reload();
  // // });
  // setLocale(val);
  active.value = false;
}
const { t } = useI18n({
  useScope: 'local',
});
</script>

<i18n lang="json">
{
  "ru": {
    "Выберите язык": "Выберите язык"
  },
  "en": {
    "Выберите язык": "Select language"
  }
}
</i18n>

<style lang="scss" module>
.LanguagePicker {
  position: relative;
}

.title {
  display: flex;
  align-items: center;
  cursor: pointer;
  text-transform: capitalize;
  @include respond-to(xs) {
    font-size: 26px;
    line-height: 30px;
    color: $base-100;
  }
}

.picker {
  position: absolute;
  right: -1.5rem;
  bottom: -1.5rem;
  visibility: hidden;
  opacity: 0;
  width: 23rem;
  padding: 1.5rem 2.2rem 2.2rem;
  background: linear-gradient(90deg, #223540 0%, #1d2d36 100%);
  backdrop-filter: blur(4px);
  border-radius: $border-radius-s;
  transform: translateY(100%);
  transition: opacity 0.3s ease-in-out;

  &._visible {
    opacity: 1;
    visibility: visible;
  }

  @include respond-to(xs) {
    top: 0;
    bottom: unset;
    left: 0;
    transform: translateY(-100%);
  }
}

.items {
  display: flex;
  flex-direction: column;
  margin-top: 1.3rem;
}

.item {
  cursor: pointer;

  &:hover {
    color: $brand-green;
  }

  &._active {
    color: $brand-green;
  }
}

.icon {
  height: 0.8rem;
  width: 0.8rem;
  margin-left: 0.5rem;
  transition: transform 0.3s ease;

  &._active {
    transform: rotate(180deg);
  }
}

.close {
  position: absolute;
  top: 1.1rem;
  right: 1.1rem;
  width: 2rem;
  height: 2rem;
  color: $base-100;
  cursor: pointer;
}
</style>
